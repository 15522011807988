<template>
    <div id="home" class="page-wrapper page-home">
        <!-- Hero -->
        <header class="main-hero" v-if="contentIsLoaded">
            <div class="img-video-asset">
                <img :src="content.hero.image.url" :alt="content.hero.image.titre" :title="content.hero.image.titre" >
                <video class="fullscreen" src="@/assets/img/hero-video-v3.mp4" playsinline autoplay muted loop></video>
            </div>

            <article class="content">
                <h1 class="title sup-title" data-inview="fadeInUp" data-delay="100">{{ content.hero.surtitre }}</h1>
                <h2 class="subtitle big-title" data-inview="fadeInUp" data-delay="300"><span v-html="content.hero.titre"></span></h2>
                <img data-inview="fadeInUp" data-delay="400" src="https://lelys.com/assets/lelys/lelys-fortis-web.jpg" alt="Logo Groupe Lelys et Fortis" title="Logo Groupe Lelys et Fortis">
            </article>

            <a href="#expertises" v-scroll-to:-70 title="Voir plus" data-inview="fadeInUp" data-delay="500">
                <svg class="scroll-down" height="20" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9094 14.6257L11.4928 21.0438V0H8.5069V21.0438L2.09025 14.6257L-0.00012207 16.7161L9.99988 26.7146L19.9999 16.7161L17.9094 14.6257Z" fill="white"/>
                </svg>
            </a>

            <div class="hero-particles"></div>
        </header>

        <!-- Expertises -->
        <section id="expertises" class="expertises site-max-width" v-if="pageIsLoaded">
            <IntroTitles :block="content.introBlock1" />
            <div class="grid-items">
                <router-link v-for="item in featuredExpertises" :key="item.id" :to="item.jsonUrl" class="item" :title="item.title" data-inview="fadeInUp" data-delay="100">
                    <div class="img-wrap-hover">
                        <img :src="item.hero.image.url" :alt="item.hero.image.titre">
                    </div>
                    <h2 class="item-title sup-title">
                        <span>{{ item.title }}</span>
                        <svg height="12" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7006 1.67238L16.835 6.8057L-4.01896e-07 6.8057L-2.97483e-07 9.19438L16.835 9.19438L11.7006 14.3277L13.3729 16L21.3717 8L13.3728 3.69128e-07L11.7006 1.67238Z" fill="#53585F"/>
                        </svg>
                    </h2>
                </router-link>
            </div>
        </section>

        <!-- Autres Expertises -->
        <section id="autres-expertises" class="autres-expertises site-max-width" v-if="pageIsLoaded">
            <div class="intro-titles">
                <div class="left">
                    <h2 class="sup-title suptitle" data-inview="fadeInUp" data-delay="100">{{ content.introBlock2.surtitre }}</h2>
                </div>
                <div class="right">
                    <div class="flickity-btns">
                        <button class="prev" @click.prevent="expertiseGoTo('prev')">
                            <svg height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="-1" y="1" width="58" height="58" transform="matrix(-1 0 0 1 58 0)" fill="white" stroke="#E8E8E8" stroke-width="2"/>
                                <path d="M29.2994 23.6724L24.165 28.8057L41 28.8057L41 31.1944L24.165 31.1944L29.2994 36.3277L27.6271 38L19.6283 30L27.6272 22L29.2994 23.6724Z" fill="#53585F"/>
                            </svg>
                        </button>
                        <button class="next" @click.prevent="expertiseGoTo('next')">
                            <svg height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="58" height="58" fill="white" stroke="#E8E8E8" stroke-width="2"/>
                                <path d="M30.7006 23.6724L35.835 28.8057L19 28.8057L19 31.1944L35.835 31.1944L30.7006 36.3277L32.3729 38L40.3717 30L32.3728 22L30.7006 23.6724Z" fill="#53585F"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="grid-items col-4 expertises-carousel" data-inview="fadeInUp" data-delay="300">
                <router-link v-for="item in otherExpertises" :key="item.id" :to="item.jsonUrl" class="item carousel-cell" :title="item.title">
                    <div class="img-wrap-hover">
                        <img :src="item.hero.image.url" :alt="item.hero.image.titre" data-inview="fadeInUp" data-delay="100">
                    </div>
                    <h2 class="item-title sup-title">
                        <span>{{ item.title }}</span>
                        <svg height="12" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7006 1.67238L16.835 6.8057L-4.01896e-07 6.8057L-2.97483e-07 9.19438L16.835 9.19438L11.7006 14.3277L13.3729 16L21.3717 8L13.3728 3.69128e-07L11.7006 1.67238Z" fill="#53585F"/>
                        </svg>
                    </h2>
                </router-link>
            </div>
        </section>

        <!-- Secteurs -->
        <section id="secteurs" class="secteurs site-max-width" v-if="pageIsLoaded">
            <IntroTitles :block="content.introBlock3" />
            <div class="grid-labels" data-inview="fadeInUp" data-delay="400">
                <router-link v-for="item in globals.secteursEntryShort.data" :key="item.title" :to="item.jsonUrl" class="label" :title="item.title">
                    <h3>
                        <span>{{ item.title }}</span>
                        <svg height="12" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7006 1.67238L16.835 6.8057L-4.01896e-07 6.8057L-2.97483e-07 9.19438L16.835 9.19438L11.7006 14.3277L13.3729 16L21.3717 8L13.3728 3.69128e-07L11.7006 1.67238Z" fill="#59BBEB"/>
                        </svg>
                    </h3>
                </router-link>
            </div>
        </section>

        <!-- R�alisations -->
        <section id="realisations" class="realisations site-max-width" v-if="pageIsLoaded">
            <IntroTitles :block="content.introBlock4" :btn="'dark'" />
            <div class="pictures-grid-items masonery-grid">
                <article v-for="(item, i) in globals.realisationsEntryShort.data.slice(0, 6)" :key="'r-' + i" class="pictures-grid-item grid-item" data-inview="fadeInUp" data-delay="200">
                    <!-- <a href="#" target="_blank"> -->
                        <div class="titles">
                            <h3>{{ item.title }}</h3>
                            <p class="small-text">{{ item.description }}</p>
                        </div>
                        <img class="thumb-img" :src="item.imageSimple1.url" :alt="item.imageSimple1.titre">
                    <!-- </a> -->
                </article>
            </div>
        </section>

        <!-- Clients -->
        <section id="clients" class="clients site-max-width" v-if="contentIsLoaded">
            <IntroTitles :block="content.introBlock5" />
            <div class="testimonials-carousel" data-inview="fadeInUp" data-delay="400">
                <div class="carousel-cell" v-for="(item, i) in content.testimonials" :key="'t-' + i">
                    <svg height="38" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.121 0.000775676C19.9041 -0.00576573 19.6872 0.029124 19.4834 0.103262C14.8799 1.73219 11.0236 4.00003 7.97331 6.92658C4.89044 9.8399 2.73881 13.2196 1.61921 16.9877C0.510494 20.6707 0 25.4879 0 31.5386V46.2599C0 47.2215 0.784391 48 1.7485 48H22.4235C23.3898 48 24.1741 47.2215 24.1741 46.2599V25.6839C24.1741 24.7222 23.3898 23.9437 22.4235 23.9437H14.2727C14.5554 20.9214 15.3726 18.6012 16.6238 16.9049C18.094 14.9119 20.7124 13.1848 24.6629 11.8895V11.8873C25.1405 11.7325 25.5262 11.3793 25.7234 10.9213C25.9205 10.4612 25.9118 9.94004 25.6971 9.48865L21.6501 0.995096C21.3697 0.404147 20.7783 0.0203698 20.121 0.000775676ZM50.2553 0.000775676C50.0384 -0.00576573 49.8215 0.029124 49.6177 0.103262C45.0142 1.73219 41.1579 4.00003 38.1076 6.92658C35.0248 9.84208 32.8753 13.2196 31.7535 16.9877C30.6448 20.6729 30.1343 25.4879 30.1343 31.5409V46.2599C30.1343 46.7222 30.3206 47.1648 30.6492 47.4919C30.9779 47.819 31.4227 48.0022 31.8872 48H52.5599C53.524 48 54.3084 47.2215 54.3084 46.2598V25.6838C54.3084 24.7222 53.524 23.9437 52.5599 23.9437H44.4069C44.6896 20.9214 45.5068 18.6012 46.758 16.9049C48.2282 14.9118 50.8466 13.1848 54.7971 11.8895V11.8873C55.2747 11.7325 55.6604 11.3792 55.8575 10.9213C56.0569 10.4612 56.046 9.94002 55.8312 9.48863L51.7843 0.995073C51.5039 0.404125 50.9127 0.0203698 50.2553 0.000775676Z" fill="#59BBEB"/>
                    </svg>
                    <p class="regular-text">
                        <span v-html="item.texte"></span>
                        <strong class="testimonial-title">{{ item.nom }}</strong>
                        <small class="testimonial-desc">{{ item.poste }}</small>
                    </p>
                </div>
            </div>
            <div class="flickity-btns">
                <button class="prev" @click.prevent="testimonialsGoTo('prev')">
                    <svg height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="-1" y="1" width="58" height="58" transform="matrix(-1 0 0 1 58 0)" fill="white" stroke="#E8E8E8" stroke-width="2"/>
                        <path d="M29.2994 23.6724L24.165 28.8057L41 28.8057L41 31.1944L24.165 31.1944L29.2994 36.3277L27.6271 38L19.6283 30L27.6272 22L29.2994 23.6724Z" fill="#53585F"/>
                    </svg>
                </button>
                <button class="next" @click.prevent="testimonialsGoTo('next')">
                    <svg height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="58" height="58" fill="white" stroke="#E8E8E8" stroke-width="2"/>
                        <path d="M30.7006 23.6724L35.835 28.8057L19 28.8057L19 31.1944L35.835 31.1944L30.7006 36.3277L32.3729 38L40.3717 30L32.3728 22L30.7006 23.6724Z" fill="#53585F"/>
                    </svg>
                </button>
            </div>
        </section>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapState, mapGetters } from 'vuex'

import Flickity from 'flickity'
import Masonry from 'masonry-layout'
import gsap from 'gsap'

import IntroTitles from '@/components/IntroTitles'

export default {
    name: 'Home',

    components: {
        IntroTitles,
    },

    data() {
        return {
            expertisesCarousel: null,
            testimonialsCarousel: null,
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        featuredExpertises() {
            if (!this.globalsIsLoaded) {
                return []
            }
            const items = this.globals.expertisesEntryShort.data.filter( i => i.featured )
            return items
        },
        otherExpertises() {
            if (!this.globalsIsLoaded) {
                return []
            }
            if (!this.featuredExpertises.length) {
                const items = this.globals.expertisesEntryShort.data
                return items
            }
            const ids = this.featuredExpertises.map( i => i.id)
            const items = this.globals.expertisesEntryShort.data.filter( i => !ids.includes(i.id) )
            return items
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.expertisesCarousel = new Flickity(document.querySelector('.expertises-carousel'), {
                            cellAlign: 'left',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: false,
                            pageDots: false,
                        })
                        this.testimonialsCarousel = new Flickity(document.querySelector('.testimonials-carousel'), {
                            cellAlign: 'center',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: false,
                            pageDots: false,
                        })
                        // Activate Mansonery
                        setTimeout(function() {
                            var elem = document.querySelector('.masonery-grid');
                            const Msry = new Masonry( elem, {
                                // options
                                itemSelector: '.grid-item',
                                columnWidth: '.grid-item',
                                percentPosition: true,
                                gutter: 25
                            })
                            initInViewAnimations()

                            setTimeout(() => {
                                Msry.reloadItems()
                            }, 2755)

                            gsap.fromTo('.scroll-down', { bottom: '3.5vw' }, { bottom: '2.5vw', duration: 1, repeat: -1, yoyo: true })
                            gsap.fromTo('.hero-particles', { opacity: '0.25', scale: '1.1625' }, { opacity: '1', scale: '1', duration: 3.5})
                        }, 575)
                    }, 155)

                    setTimeout(() => {
                        this.expertisesCarousel.resize()
                    }, 2555);
                }
            },
            immediate: true,
        },
    },

    methods: {
        expertiseGoTo(direction) {
            if (direction === 'prev') {
                this.expertisesCarousel.previous()
                return
            }
            this.expertisesCarousel.next()
        },
        testimonialsGoTo(direction) {
            if (direction === 'prev') {
                this.testimonialsCarousel.previous()
                return
            }
            this.testimonialsCarousel.next()
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-wrapper {
    min-height: 90vh;
}
</style>
